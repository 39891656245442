<template>
  <v-container>
    <v-row>
      <v-breadcrumbs large :items="items_nav">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-card class="ma-4">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="dataTable"
        :search="search"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:item.data="{ item }">
            {{ item.data | dateTime }}
        </template>
        <template v-slot:item.mensagem="{ item }">
            {{ item.mensagem | more }}
        </template>
        <!-- <template v-slot:item.usuario_id="{ item }">
            {{ item.usuario.nome }}
        </template>
        <template v-slot:item.fornecedor_id="{ item }">
            {{ (item.fornecedor.nome_empresa)}}
        </template> -->
        <template v-slot:item.actions="{ item }">
          <v-row>

              <v-btn
                small
                fab
                dark
                class="mx-2 my-2  green darken-3"
                @click="changeStatus(item, 'aprovar')"
              >
                <v-icon small dark>mdi-check-bold</v-icon>
              </v-btn>


              <v-btn
                small
                fab
                dark
                class="mx-2 my-2  red darken-3"
                @click="changeStatus(item, 'excluir')"
              >
                <v-icon small dark>mdi-close-thick</v-icon>
              </v-btn>

          </v-row>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getDataFromApi">Reiniciar</v-btn>
        </template>
      </v-data-table>

    </v-card>

    <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Realizando operação por favor aguarde...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogChangeStatus" max-width="600px">
      <v-card>
        <v-card-title class="headline">
          Alterar Situação
        </v-card-title>
        <v-card-text>
          <v-row>
            <div class="col">
              <b>Usuário:</b><br />
              {{ itemSelected.usuario.nome }}
            </div>
            <div class="col">
              <b>Fornecedor:</b><br />
              {{ itemSelected.fornecedor.nome_empresa }}
            </div>
          </v-row>
          <v-row>
            <div class="col">
              <b>Título:</b><br />
              {{ itemSelected.titulo }}
            </div>
            <div class="col">
              <b>Nota:</b><br />
              {{ itemSelected.nota }}
            </div>
            <div class="col">
              <b>Data:</b><br />
              {{ itemSelected.data | dateTime }}
            </div>
          </v-row>
          <v-row>
            <div class="col">
              <b>Mensagem:</b><br />
              {{ itemSelected.mensagem }}
            </div>
          </v-row>
          <br>
          <p>
            Deseja realmente {{ this.itemConfirmStatus }} essa avaliação? Essa operação não poderá ser desfeita!
          </p>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-row
            align="center"
            justify="end"
            class="my-4"
          >
            <v-btn
            class="mx-4"
              color="red darken-3 btn-modal"
              @click="dialogChangeStatus = false"
            >
              Cancelar
            </v-btn>
            <v-btn class="mx-4" color="green darken-1 btn-modal" @click="setModalStatus()">
              Continuar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMessage" width="480">
        <v-card class="mx-auto" dark>
        <v-card-title>
            <span class="title font-weight-light">{{ message.title }}</span>
        </v-card-title>
        <v-card-text class="headline font-weight-bold">
            {{ message.body }}
        </v-card-text>
        </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import database from "@/services/database";
import store from '@/store'

export default {
  name: "Fornecedores",
  data() {
    return {
      items_nav: [
        {
          text: "Sistema",
          disabled: false,
        },
        {
          text: "Fornecedores",
          disabled: true,
        },
        {
          text: "Avaliações",
          disabled: true,
        }
      ],
      loading: true,
      search: "",
      singleSelect: false,
      selected: [],
      itemSelected: {
        data: "",
        titulo: "",
        mensagem: "",
        nota: 0.0,
        usuario_id: "",
        fornecedor_id: "",
        usuario: {
          nome: ""
        },
        fornecedor: {
          nome_empresa: ""
        }
      },
      message: {
        title: "",
        body: ""
      },
      dialogMessage: false,
      dialogProgress: false,
      dialogEdit: false,
      dialogChangeStatus: false,
      itemConfirmStatus: "",
      address: [],
      registers: [],
      headers: [
        { text: "Data", value: "data" },
        { text: "Título", value: "titulo" },
        { text: "Mensagem", value: "mensagem" },
        { text: "Nota", value: "nota" },
        /* { text: "Usuário", value: "usuario_id" },
        { text: "Fornecedor", value: "fornecedor_id" }, */
        { text: "Ações", value: "actions" },
        
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi: async function() {
      this.loading = true;
      var data = await database.getAllAvaliacoes();
      const promises = data.map(async (e, index) => {
          data[index]["usuario"] = await this.getUsuario(e.usuario_id);
          data[index]["fornecedor"] = await this.getFornecedor(e.fornecedor_id);
      });
      await Promise.all(promises);
      this.dataTable = data;
      this.loading = false;
    },
    getUsuario: async function(id) {
        return await database.getUsuarioById(id);
    },
    getFornecedor: async function(id) {
        return await database.getFornecedorById(id);
    },
    changeStatus: function(item, acao) {
      this.itemSelected = item;
      this.itemConfirmStatus = acao;
      this.dialogChangeStatus = true;
    },
    setModalStatus: async function() {
      this.dialogChangeStatus = false;
      this.dialogProgress = true;
      let novoStatus = this.itemConfirmStatus === "aprovar" ? "APROVADO" : "REPROVADO";
      var result = null;
      if (novoStatus == "APROVADO") {
        result = await database.updateAvaliacaoStatus(
          this.itemSelected.id,
          novoStatus
        );
      } else {
        result = await database.deleteAvaliacaoById(this.itemSelected.id);
      }      
      if (result) {
        this.dialogProgress = false;
        this.getDataFromApi();
        this.message.title = "Operação realizada";
        this.message.body = "Alteração realizada com sucesso!";
        this.dialogMessage = true;
      } else {
        this.dialogProgress = false;
        this.message.title = "Operação falhou";
        this.message.body = "Verifique sua conexão e tente novamente!";
        this.dialogMessage = true;
      }
    }
  },
};
</script>
